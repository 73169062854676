import React from "react"

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36.697"
    height="43.157"
    viewBox="0 0 36.697 43.157"
    width={24}
    height={24}
    className="search-svg"
  >
    <defs>
      <style>{`.search-svg .a{fill:#7E7E7E;}`}</style>
    </defs>
    <g transform="translate(-22.449 -19.643)">
      <g transform="translate(0)">
        <path
          className="a"
          d="M33.569,40.857,25.332,30.227A15.725,15.725,0,0,1,4.714,28.364,16.78,16.78,0,0,1,0,16.616,16.78,16.78,0,0,1,4.714,4.867a15.738,15.738,0,0,1,22.76,0,16.778,16.778,0,0,1,4.714,11.749,16.78,16.78,0,0,1-4.714,11.749q-.253.262-.516.51l8.57,11.059c.74.956,1.716,2.284.791,3.048a.7.7,0,0,1-.451.175C35.059,43.157,34.192,41.661,33.569,40.857ZM2.283,16.616a14.056,14.056,0,0,0,13.81,14.258A14.056,14.056,0,0,0,29.905,16.616,14.056,14.056,0,0,0,16.094,2.357,14.056,14.056,0,0,0,2.283,16.616Z"
          transform="translate(22.449 19.643)"
        />
      </g>
    </g>
  </svg>
)

export default SearchIcon
