import React from "react"

const FeedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33.512"
    height="33.512"
    viewBox="0 0 33.512 33.512"
    width={24}
    height={24}
    className="feed-svg"
  >
    <g id="FEED" transform="translate(-5 -5)">
      <line
        id="Linha_7"
        data-name="Linha 7"
        transform="translate(6 38)"
        fill="none"
        stroke="#103d45"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        className="a"
      />
      <path
        id="Caminho_2238"
        data-name="Caminho 2238"
        d="M16.5,37.512A10.5,10.5,0,0,0,6,27.008m21.008,10.5A21.008,21.008,0,0,0,6,16.5M37.512,37.512A31.512,31.512,0,0,0,6,6"
        fill="none"
        stroke="#103d45"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        className="a"
      />
    </g>
  </svg>
)

export default FeedIcon
