import React from "react"

const Menu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.106"
      height="26.785"
      viewBox="0 0 33.106 26.785"
    >
      <g id="MENU" transform="translate(-4.25 -3.583)">
        <path
          id="Caminho_2432"
          data-name="Caminho 2432"
          d="M5,15H36.606M5,22.778H36.606"
          transform="translate(0 6.841)"
          fill="none"
          stroke="#103d45"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <rect
          id="Retângulo_16844"
          data-name="Retângulo 16844"
          width="31.606"
          height="9.482"
          transform="translate(5 4.333)"
          fill="none"
          stroke="#103d45"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </svg>
  )
}

export default Menu
