import React from "react"

const Hashtag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      className="hashtag-svg"
      viewBox="0 0 36.915 33.41"
    >
      <style>
        {`.hashtag-svg .a{fill:#103d45;stroke:#103d45;stroke-linecap:round;stroke-linejoin:round;stroke-width:1px;}`}
      </style>
      <g id="Camada_2" data-name="Camada 2" transform="translate(0 0.01)">
        <g id="Camada_1" data-name="Camada 1" transform="translate(0 -0.01)">
          <path
            id="Caminho_2450"
            className="a"
            data-name="Caminho 2450"
            d="M35.983,8.755h-6.4l.278-1.036,1.758-6.557a.93.93,0,1,0-1.8-.483l-2,7.466-.164.609H13.512l.278-1.036,1.758-6.557A.931.931,0,0,0,14.89.022a.95.95,0,0,0-.241-.031.928.928,0,0,0-.9.688l-2,7.467-.164.609H4.44a.931.931,0,1,0,0,1.861h6.65l-.277,1.035L7.993,22.167l-.164.609H.931a.931.931,0,0,0,0,1.861h6.4l-.278,1.035L5.291,32.229a.931.931,0,0,0,.658,1.14.941.941,0,0,0,.239.031.928.928,0,0,0,.9-.688l2-7.466.164-.609H23.4l-.278,1.035-1.758,6.557a.93.93,0,1,0,1.8.483l2-7.466.164-.609h7.153a.931.931,0,1,0,0-1.861H25.828l.277-1.035,2.82-10.515.164-.609h6.894a.931.931,0,0,0,0-1.861Zm-9.1,2.9-2.82,10.515-.164.609H9.751l.28-1.035,2.82-10.515.164-.609H27.162Z"
            transform="translate(0 0.01)"
            fill="#103d45"
          />
        </g>
      </g>
    </svg>
  )
}

export default Hashtag
