import React from "react"

const HomeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41.952"
    height="46.335"
    viewBox="0 0 41.952 46.335"
    width={24}
    height={24}
    className="home-svg"
  >
    <style>
      {`.home-svg .a{fill:none;stroke:#103d45;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}`}
    </style>
    <defs></defs>
    <g transform="translate(1.138 1.138)">
      <path
        className="a"
        d="M3,19.933v25.9H42.452v-25.9L22.726,2Z"
        transform="translate(-2.888 -1.888)"
      />
      <path
        className="b"
        d="M.832,17.158,20.178-.062l19.01,18.227v25.5H26.216V25.545l-13.754.671.895,18.227L-.062,43.661Z"
        transform="translate(0.063 0.063)"
        fill="transparent"
      />
      <rect
        className="a"
        width="13.151"
        height="17.534"
        transform="translate(13.262 26.413)"
      />
    </g>
  </svg>
)

export default HomeIcon
