import React from "react"

const TopicsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49.43"
    height="35.173"
    viewBox="0 0 49.43 35.173"
    width={24}
    height={24}
    className="topics-svg"
  >
    <defs>
      <style>{`.topics-svg .a{fill:none;stroke:#103d45;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}`}</style>
    </defs>
    <g transform="translate(0 1)">
      <path
        className="a"
        d="M10,7H53.111M10,23.434H53.111M10,39.867H53.111"
        transform="translate(-4.681 -7)"
      />
      <line className="a" transform="translate(0 1.112)" />
      <line className="a" transform="translate(0 16.371)" />
      <line className="a" transform="translate(0 34.173)" />
    </g>
  </svg>
)

export default TopicsIcon
