import React from "react"

const CollectionsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46.412"
    height="43.515"
    viewBox="0 0 46.412 43.515"
    width={24}
    height={24}
    className="collections-svg"
  >
    <style>
      {`.collections-svg .a{fill:none;stroke:#103d45;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}`}
    </style>
    <defs></defs>
    <g transform="translate(1 1)">
      <rect
        className="a"
        width="16.911"
        height="16.911"
        transform="translate(0 0)"
      />
      <rect
        className="a"
        width="16.911"
        height="16.911"
        transform="translate(27.501 0)"
      />
      <rect
        className="a"
        width="16.911"
        height="16.911"
        transform="translate(0 24.604)"
      />
      <rect
        className="a"
        width="16.911"
        height="16.911"
        transform="translate(27.501 24.604)"
      />
    </g>
  </svg>
)

export default CollectionsIcon
