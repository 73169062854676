import React from "react"

const GlobeIcon = () => {
  return (
    <svg
      id="LINGUAGEM"
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      viewBox="0 0 43 43"
    >
      <path
        id="Caminho_11608"
        data-name="Caminho 11608"
        d="M0,0H43V43H0Z"
        fill="none"
      />
      <path
        id="Caminho_11609"
        data-name="Caminho 11609"
        d="M19.917,2A17.917,17.917,0,1,0,37.833,19.917,17.923,17.923,0,0,0,19.917,2ZM18.125,34.125A14.313,14.313,0,0,1,5.583,19.917,14.545,14.545,0,0,1,5.96,16.71l8.582,8.582v1.792a3.594,3.594,0,0,0,3.583,3.583Zm12.362-4.551a3.555,3.555,0,0,0-3.4-2.49H25.292V21.708A1.8,1.8,0,0,0,23.5,19.917H12.75V16.333h3.583a1.8,1.8,0,0,0,1.792-1.792V10.958h3.583a3.594,3.594,0,0,0,3.583-3.583V6.64a14.3,14.3,0,0,1,5.2,22.933Z"
        transform="translate(1.583 1.583)"
        fill="#48235c"
      />
    </svg>
  )
}

export default GlobeIcon
