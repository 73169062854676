import React from "react"

const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 20.245 36.181"
    >
      <path
        id="FACEBOOK"
        d="M40.114.008h-4.5A7.9,7.9,0,0,0,27.3,8.539v3.935H22.785a.708.708,0,0,0-.708.708v5.7a.708.708,0,0,0,.708.708h4.521V33.966a.708.708,0,0,0,.708.708h5.9a.708.708,0,0,0,.708-.708V19.587H39.9a.708.708,0,0,0,.708-.708v-5.7a.708.708,0,0,0-.708-.708H34.616V9.138c0-1.6.381-2.416,2.47-2.416h3.028a.708.708,0,0,0,.708-.708V.721a.708.708,0,0,0-.708-.713Z"
        transform="translate(-21.327 0.757)"
        fill="none"
        stroke="#c5c5c5"
        stroke-width="1.5"
      />
    </svg>
  )
}

export default Facebook
