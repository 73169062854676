import React from "react"

const Instagram = () => {
  return (
    <svg
      id="INSTAGRAM"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 31.467 31.467"
    >
      <g id="Grupo_9127" data-name="Grupo 9127">
        <g id="Grupo_9126" data-name="Grupo 9126">
          <path
            id="Caminho_3508"
            data-name="Caminho 3508"
            d="M22.326,0H9.141A9.151,9.151,0,0,0,0,9.141V22.326a9.151,9.151,0,0,0,9.141,9.141H22.326a9.151,9.151,0,0,0,9.141-9.141V9.141A9.151,9.151,0,0,0,22.326,0ZM29.9,22.326c0,3.685-3.89,7.5-7.574,7.5H9.141c-3.685,0-7.692-3.816-7.692-7.5V9.141c0-3.685,4.007-7.383,7.692-7.383H22.326c3.685,0,7.574,3.7,7.574,7.383Z"
            fill="#c5c5c5"
          />
        </g>
      </g>
      <g
        id="Grupo_9129"
        data-name="Grupo 9129"
        transform="translate(7.252 7.252)"
      >
        <g
          id="Grupo_9128"
          data-name="Grupo 9128"
          transform="translate(1.604 1.604)"
        >
          <path
            id="Caminho_3509"
            data-name="Caminho 3509"
            d="M124.877,118a6.877,6.877,0,1,0,6.877,6.877A6.885,6.885,0,0,0,124.877,118Zm0,12.515a5.41,5.41,0,0,1-5.444-5.638,5.35,5.35,0,0,1,5.444-5.569,5.6,5.6,0,0,1,5.588,5.569A5.663,5.663,0,0,1,124.877,130.515Z"
            transform="translate(-118 -118)"
            fill="#c5c5c5"
          />
        </g>
      </g>
      <g
        id="Grupo_9131"
        data-name="Grupo 9131"
        transform="translate(23.729 6.694)"
      >
        <g
          id="Grupo_9130"
          data-name="Grupo 9130"
          transform="translate(-0.229 -0.338)"
        >
          <circle
            id="Elipse_1392"
            data-name="Elipse 1392"
            cx="1"
            cy="1"
            r="1"
            transform="translate(0 0.078)"
            fill="#c5c5c5"
          />
        </g>
      </g>
    </svg>
  )
}

export default Instagram
