import React from "react"

const TrendIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48.834"
    height="32.037"
    viewBox="0 0 48.834 32.037"
    width={24}
    height={24}
    className="trend-svg"
  >
    <defs>
      <style>{`.trend-svg .a{fill:none;stroke:#103d45;stroke-linecap:round;stroke-width:3px;}`}</style>
    </defs>
    <g transform="translate(1.411 1.414)">
      <path
        className="a"
        d="M3,35.068,18.262,17.542,28.436,29.226,47.271,7.595"
        transform="translate(-3 -5.857)"
      />
      <path
        className="a"
        d="M20.405,8.737,22.142,7"
        transform="translate(23.867 -7)"
      />
      <path
        className="a"
        d="M26.763,15.763V7H18"
        transform="translate(19.245 -7)"
      />
    </g>
  </svg>
)

export default TrendIcon
